// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --main-bg-color: #ffffff;
    --secondary--color: #f9f9f9;
    --primary-color: #5c2680;

    --font-size: 16px;
  }





body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.5s ease, color 0.5s ease;
}

button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/styles/Variables.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,2BAA2B;IAC3B,wBAAwB;;IAExB,iBAAiB;EACnB;;;;;;AAMF;EACE,yCAAyC;EACzC,wBAAwB;EACxB,uDAAuD;AACzD;;AAEA;EACE,wCAAwC;EACxC,+BAA+B;EAC/B,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,uDAAuD;AACzD;;AAEA;EACE,YAAY;AACd","sourcesContent":[":root {\n    --main-bg-color: #ffffff;\n    --secondary--color: #f9f9f9;\n    --primary-color: #5c2680;\n\n    --font-size: 16px;\n  }\n\n\n\n\n\nbody {\n  background-color: var(--background-color);\n  color: var(--text-color);\n  transition: background-color 0.5s ease, color 0.5s ease;\n}\n\nbutton {\n  background-color: var(--button-bg-color);\n  color: var(--button-text-color);\n  border: none;\n  padding: 10px 20px;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease, color 0.3s ease;\n}\n\nbutton:hover {\n  opacity: 0.9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
