import { useEffect } from "react"

const ErrorPage = () => {

    return (
        <div className="error-page">
            <div className="error-container">
                <div className="error-image-section">
                    <div className="error-image-item">

                        <img src={"/images/not-found.gif"} alt="" />
                    </div>
                </div>

                <div className="error-content-section">
                    <div>
                        <div className="error-content">

                            <p>User Not Found</p>
                        </div>
                        <div className="error-btn-section">
                            <p className="error-discribtion">Oops! The user may not exist in our system.<br></br>The user's account might have been deleted or deactivated.</p>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )

}

export default ErrorPage

