// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-in {
  opacity: 0;
  animation: fadeIn 2.5s forwards; /* Adjust the duration as needed */
}

.card-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-item:hover {
  transform: scale(1.05); /* Slightly enlarge the card on hover */
 
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Profile.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,+BAA+B,EAAE,kCAAkC;AACrE;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,sBAAsB,EAAE,uCAAuC;;AAEjE;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".fade-in {\n  opacity: 0;\n  animation: fadeIn 2.5s forwards; /* Adjust the duration as needed */\n}\n\n.card-item {\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n.card-item:hover {\n  transform: scale(1.05); /* Slightly enlarge the card on hover */\n \n}\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
