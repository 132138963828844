import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/users.css'; // Import the CSS file
import Popup from '../../components/PopUps/Popup';
import { useNavigate, useParams} from 'react-router-dom';
import baseUrl from '../../Config/config';
import api from '../../api/api';
// import Sidebar from './SideBar';
import Navbar from '../../components/NavBar/Navbar';

const ChangePwdPage = () => {

  const fetchCsrfToken = async () => {
    const response = await api.get(`${baseUrl}/csrf-token`);
    const cookies = document.cookie.split('; ');
    const token = cookies.find((row) => row.startsWith('XSRF-TOKEN=')).split('=')[1];
    return token;
  };

    const { id } = useParams();
    const capitalRegex = /^(?=.*[A-Z])/;
    const smallRegex = /^(?=.*[a-z])/;
    const numberRegex = /^(?=.*\d)/;
    const lengthRegex = /^.{8,}$/;
    const specialRegex = /^(?=.*[!@#$%^&*])/;
    const [details, setDetails] = useState(null);
    const navigate = useNavigate()
    const [passProgress,setPassProgress] = useState(0);
    const [formData, setFormData] = useState({
        new_password: '',
        confirm_password: ''
      });
    const [popup, setPopup] = useState(null);
  
    useEffect(() => {
      const fetchDetails = async () => {
        console.log('hello there !')
        try {
          const token = localStorage.getItem('token'); //retrive the token from the local storage
          if(!token){
            navigate("/login")
          }
        } catch (error) {
          console.error('Error fetching details:', error);
        }
      };
  
      fetchDetails();
    }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const calculatePasswordStrength = (password) => {
      let passProgress = 0;
    
      if (capitalRegex.test(password)) {
        passProgress += 0.2;
      }
      if (smallRegex.test(password)) {
        passProgress += 0.2;
      }
      if (numberRegex.test(password)) {
        passProgress += 0.2;
      }
      if (lengthRegex.test(password)) {
        passProgress += 0.2;
      }
      if (specialRegex.test(password)) {
        passProgress += 0.2;
      }
    
      return passProgress; // Multiply by 100 to get percentage
    };
    setPassProgress(calculatePasswordStrength(value));

    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if any input field is empty
    if (Object.values(formData).some((value) => !value.trim())) {
      setPopup({ type: 'error', message: 'All fields must be filled out' });
      return;
    }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{5,}$/;
    const isPasswordValid = (password) => {
        return passwordRegex.test(password);
    };

    if (formData.new_password !== formData.confirm_password) {
        setPopup({ type: 'error', message: 'New password and confirm password do not match' });
        return;
    }else if(!isPasswordValid(formData.new_password)){
        setPopup({ type: 'error', message: 'Password should contain upper case, lower case, special characters and minimum length of 5 characters !' });
        return;
    }else{
        try {
          const token1 = await fetchCsrfToken();
          const token = localStorage.getItem('token'); //retrive the token from the local storage
          const res = await api.post(`${baseUrl}/api/update-user/${id}`, formData, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          console.log(res);
          setPopup({ type: 'sucess', message: 'Successfully changed the password.! Please login again to continue.' });
          localStorage.setItem("authenticated", false)
          localStorage.removeItem('token');
          navigate("/login")
        } catch (error) {
          console.error('Error changing the password:', error);
          if (error.response && error.response.data) {
            setPopup({ type: 'error', message: error.response.data });
          } else {
            setPopup({ type: 'error', message: 'Change to new password is not completed due to some technical issues' });
          }
        }
    }

  };

  const closePopup = () => {
    setPopup(null);
    window.location.reload();
  };

  return (
<div className='content-section  col-12 col-md-12 col-xl-10 change-password-main'>
    
        <Navbar />
        {
          <div className='center'>

            <div className='form-control'>
              <h2>Update Password</h2>
              <form className="edit-form" onSubmit={handleSubmit}>
                <div className="form-group">
                <label htmlFor="new_password">New Password:</label>
                <input type="password" id="new_password" name="new_password" onChange={handleChange} />
                </div>
        
                <div className="form-group">
                <label htmlFor="confirm_password">Confirm Password:</label>
                <input type="password" id="confirm_password" name="confirm_password" onChange={handleChange} />
                </div>
            
                <button className='change-password-btn' type="submit">Update</button>

                <div className="form-group">
                <progress value={passProgress} className='progress_bar'/>
                <label htmlFor="confirm_password">Must contain at least:</label>
                </div>
                <label htmlFor="confirm_password">{lengthRegex.test(formData.new_password) ? (<span>&#x2714;</span>) :  (<span>&#x274C;</span>)}8 Characters</label>
                <label htmlFor="confirm_password">{capitalRegex.test(formData.new_password) ? (<span>&#x2714;</span>) :  (<span>&#x274C;</span>)}1 capital letter</label>
                <label htmlFor="confirm_password">{smallRegex.test(formData.new_password) ? (<span>&#x2714;</span>) :  (<span>&#x274C;</span>)}1 small letter</label>
                <label htmlFor="confirm_password">{specialRegex.test(formData.new_password) ? (<span>&#x2714;</span>) :  (<span>&#x274C;</span>)}1 special character</label>
                <label htmlFor="confirm_password">{numberRegex.test(formData.new_password) ? (<span>&#x2714;</span>) :  (<span>&#x274C;</span>)}1 numerical character</label>
              </form>
            </div>
          </div>
        }
 

  {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
</div>
    
  );
};

export default ChangePwdPage;