import { useNavigate } from "react-router"


const LogoutPage = () => {
    const navigate = useNavigate()

    const handleBackToLogin = () => {
        navigate("/hero")
    }
    return (
        <div className="logout-page">
            <div className="logout-container">
                <div className="logout-image-section">
                    <div className="logout-image-item">

                    <img src={"/images/logout.jpg"} alt="" />
                    </div>
                </div>

                <div className="logout-content-section">
                    <div>
                        <div className="logout-content">

                            <p >Logout Successful</p>
                        </div>
                        <div className="logout-btn-section">
                            <button className="back-to-login-button" onClick={handleBackToLogin}>
                                Login Again
                            </button>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}
export default LogoutPage