import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/sidebar.css';
import baseUrl from '../../Config/config';
import api from '../../api/api';

const Sidebar = ({setAuthenticated}) => {
    // const navigate = useNavigate();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [userDetails, setDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false)
    const id = localStorage.getItem('user_id')

    useEffect(() => {
        const fetchDetails = async () => {
          try {
            const token = localStorage.getItem('token'); //retrive the token from the local storage
            const response = await api.get(`${baseUrl}/api/user-details/${Number(id)}`, {
                headers: {
                  'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
              });
            setDetails(response.data);
          } catch (error) {
            console.error('Error fetching details:', error);
          }
        };
    
        fetchDetails();
    }, [id]);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
      try {
        await api.post(`${baseUrl}/api/logout`, { id: Number(id) });
        // Remove token and user_id from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('authenticated');
        setAuthenticated(false);
        window.location.href = '/logout-success';
        window.close(); // Close the window
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    const toggleExpand = () => {
    setIsExpanded(!isExpanded)
    }
    return (
      
          
            <div className="sid_nav_main">
               {(
                <button className="expand_button" onClick={toggleExpand}>
                    {!isExpanded ? `☰` : `X`}
                </button>
            )}
              <div className={`sidebar visible ${isExpanded ? 'expanded' : 'collapsed'}`}>

                  <div className='main-logo-section'>
                    <Link to={`/home/${id}`}>
                        <img src='/images/polus.webp' alt="Logo" className="logo" />
                    </Link>
                  </div>
                  <div className='sidebar-contect-section'> 
                    <div className='profile-label-section'>
                      {userDetails && (
                        <Link to={`/profile/${id}`}>
                          <div className="user-profile">
                              {userDetails.profile_pic == ''|| userDetails.profile_pic == null ? 
                                <img width={50} height={50} src="/images/profile-user.png" alt="Profile Pic" className='profile-pic'/> 
                              : <img width={50} height={50} src={userDetails.profile_pic} alt="Profile Pic" className='profile-pic'/>
                              }
                              
                              <div className="user-info">
                                  <span className="user-email">{userDetails.first_name+' '+userDetails.last_name}</span>
                              </div>
                          </div>
                        </Link>
                      )}
                    </div>
                    <div className='sidebar-menu-section'>
                      <Link to={`/home/${id}`}>
                          <img src='/images/icons8-home-24.png' alt="Home Icon" className="sidebar-icon" />
                          <span className='menu-name'>Home</span>
                      </Link>
                      {/* {userDetails && userDetails.role_id === '1' && ( */}
                          <Link to={`/all-users/${id}`}>
                              <img src='/images/icons8-users-48 (1).png' alt="Home Icon" className="sidebar-icon" />
                              <span className='menu-name'>Address Book</span>
                          </Link>
                      {/* s */}
                      <div className="logout">
                          <Link to="" onClick={handleLogout}>
                              <img src='/images/icons8-logout-26.png' alt="Home Icon" className="sidebar-icon" />
                              <span className='menu-name'>Logout</span>
                          </Link>
                      </div>
                    </div>  
                  </div>
              </div>
                  
            </div>
    
    );
};

export default Sidebar;
