import { Route, Routes, useLocation, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/LoginPage/Login";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import { HomePage } from "./pages/HomePage/HomePage";
import { UsersPage } from "./pages/AllUsersPage/UsersPage";
import UserViewPage from "./pages/UserViewPage/UserViewPage";
import AddUserPage from "./pages/AddUserPage/AddUserPage";
import UpdateUserPage from "./pages/UpdateUser/UpdateUserPage";
import ChangePwdPage from "./pages/ChangePasswordPage/ChangePwdPage";
import Sidebar from "./components/SideBars/SideBar";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import LogoutPage from "./pages/LogoutPage/LogoutPage";
import MaintenanceMode from "./pages/MaintenanceMode/MaintenanceMode";
import { useEffect, useState } from "react";
import baseUrl from '../src/Config/config';
import api from '../src/api/api';

function App() {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") === "true");
  const [maintenanceStatus, setMaintenanceStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("authenticated", authenticated);
  }, [authenticated]);

  // Maintenance check on load/refresh
  useEffect(() => {
    const fetchMaintenanceDetails = async () => {
      const tool = 'corporateportal';
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.warn('Access token is missing, skipping maintenance check.');
        return;
      }

      try {
        const response = await api.get(`${baseUrl}/api/get-maintenance-details/${tool}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        const isMaintenanceActive = response.data.maintenanceStatus || false;
        setMaintenanceStatus(isMaintenanceActive);

        if (isMaintenanceActive && !location.pathname.includes("/maintenance-mode")) {
          navigate(`/maintenance-mode/${tool}`);
        }
      } catch (error) {
        console.error("Failed to fetch maintenance details:", error);
        setMaintenanceStatus(false);
      }
    };

    fetchMaintenanceDetails();
  }, [navigate, location.pathname]);

  // On maintenance end, redirect to home page if refreshed or on maintenance page
  useEffect(() => {
    // Extract the toolName from the URL path
    const toolName = location.pathname.split("/maintenance-mode/")[1];

    if (!maintenanceStatus && location.pathname.includes("/maintenance-mode")) {
      // If toolName is "corporateportal", redirect to home or login
      if (toolName === 'corporateportal') {
        const userId = localStorage.getItem("user_id");
        if (userId) {
          navigate(`/home/${userId}`);
        } else {
          navigate("/login");
        }
      }
      // Otherwise, do not redirect, allow for other tools
    }
  }, [maintenanceStatus, location.pathname, navigate]);

  return (
    <div className="App">
      <Routes>
        {/* Login and Public Routes */}
        <Route path="/login" element={<Login setAuthenticated={setAuthenticated} />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/logout-success" element={<LogoutPage />} />
        <Route path="/maintenance-mode/:tool" element={<MaintenanceMode />} />

        {/* Authenticated Routes */}
        {authenticated ? (
          <>
            {/* Redirect logged-in user to home page */}
            <Route path="/hero" element={<Navigate to={`/home/${localStorage.getItem("user_id")}`} replace />} />

            {/* Main content with Sidebar for authenticated users */}
            <Route path="*" element={
              <div className="main-content flex-column flex-xl-row">
                {/* Sidebar */}
                {!location.pathname.includes("/change-pwd") && !location.pathname.includes("/maintenance-mode") && (
                  <div className="sidebar-section min-vh-0">
                    <Sidebar setAuthenticated={setAuthenticated} /> 
                  </div>
                )}

                {/* Authenticated Pages */}
                <Routes>
                  <Route path="/home/:id" element={<HomePage />} />
                  <Route path="/profile/:id" element={<ProfilePage />} />
                  <Route path="/all-users/:id" element={<UsersPage />} />
                  <Route path="/add-user/:id" element={<AddUserPage />} />
                  <Route path="/view-user/:id/:empId" element={<UserViewPage />} />
                  <Route path="/edit-user/:id/:empId" element={<UpdateUserPage />} />
                  <Route path="/change-pwd/:id" element={<ChangePwdPage />} />
                </Routes>
              </div>
            } />
          </>
        ) : (
          /* If not authenticated, redirect to login */
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}

        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>

    </div>
  );
}

export default App;

