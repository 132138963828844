import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import axios from 'axios'
import baseUrl from '../../Config/config';
// import Popup from './Popup';
import { useNavigate, useParams} from 'react-router-dom';
import Sidebar from '../../components/SideBars/SideBar';
import '../../styles/users.css' 
import Navbar from '../../components/NavBar/Navbar';
import Popup from '../../components/PopUps/Popup';
import api from '../../api/api';
// import SideBarPage from './SideBarPage';

const AddUserPage = () => {
    const {id} = useParams();
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        employeeId: '',
        email: '',
        contact_no: '',
        seat_allocation:'',
        roleId: '',
        joining_date:'',
        access_type:'',
        creatingAdminId: Number(id)
    });
    const [popup, setPopup] = useState(null);
    const [image,setImage]= useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const token = localStorage.getItem('token'); //retrive the token from the local storage
    const [selectedOption, setSelectedOption] = useState(null);
    const [roleOptions, setRoleOptions] = useState(null);
    useEffect(() => {
      if(!token){
        navigate("/login")
      }
    });

    const fetchDetails = async () => {
      try {
        if (!token) {
            navigate("/login");
        } else {
          const response = await api.get(`${baseUrl}/api/all-roles`, {
              headers: {
                  'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
              },
          });
          const options = response.data.map(role => ({
            value: role.roleId,
            label: role.roleName
          }));
          setRoleOptions(options);
        }
      } catch (error) {
          console.error('Error fetching details:', error);
      }
    };
    useEffect(() => {
      fetchDetails();
    },[]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      // Convert to number if the field is employeeId or contact_no
      const newValue = (name === 'employeeId' || name === 'contact_no' || name === "access_type") ? Number(value) : value;
      setFormData({ ...formData, [name]: newValue });
    };
    
    const convertToBase64 = (e) => {
      let file = e.target.files[0];
      if (file) {
        if (file.size > 100 * 1024) { // 100KB = 100 * 1024 bytes
          setPopup({ type: 'error', message: 'File size must be below 100 KB' });
          e.target.value = ''; // Reset the file input field
          return;
        } else {
          setPopup({ type: 'info', message: 'File size: ' + file.size + ' bytes' });
        }
  
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          console.log(reader.result);
          setImage(reader.result);
        };
      }
    };

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      console.log(file);
      setSelectedImage(file);
      setFormData({ ...formData, profile_pic: file }); // Assuming the name of the input field is "profile_pic"
    };

    const handleSelectRoleChange = (data,action) => {
      if (action.name === 'role' && data !== null) {
        setSelectedOption(data)
        setFormData({
          ...formData,
          [action.name]: action.name,
          roleId : data.value
        });
      } else {
        setSelectedOption(null)
        setFormData({
          ...formData,
          [action.name]: null,
          roleId : null
        })
      }
    };

    const handleSubmit = async (e) => {
      // console.log(Object.values(formData).some((value) => !value.trim()))
      e.preventDefault();
      const requestData = {
        first_name: formData.first_name,
        last_name:formData.last_name,
        employeeId:formData.employeeId,
        email:formData.email,
        contact_no:formData.contact_no,
        seat_allocation:formData.seat_allocation,
        profile_pic:image,
        roleId:formData.roleId,
        joining_date:formData.joining_date,
        access_type:formData.access_type,
        creatingAdminId: formData.creatingAdminId
      }
      // Validation: Check if any input field is empty or whitespace-only
      if (
        Object.entries(requestData).some(([key, value]) => {
          // Skip validation for the profile_pic field
          if (key === 'profile_pic') {
            return false;
          }
      
          // Check if the value is a string and empty or whitespace-only
          if (typeof value === 'string') {
            return !value.trim();
          }
      
          // If the value is not a string, assume it's valid
          return false;
        })
      ) {
        setPopup({ type: 'error', message: 'All fields must be filled out' });
        return;
      }
  
      try {
        const token = localStorage.getItem('token'); //retrive the token from the local storage
        const res = await api.post(`${baseUrl}/api/user-register`, requestData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setPopup({ type: 'success', message: res.data });
        setFormData({
          first_name: '',
          last_name: '',
          employeeId: '',
          email: '',
          contact_no: '',
          seat_allocation:'',
          profile_pic: '',
          role: '',
          roleId: '',
          joining_date:'',
          access_type:'',
          creatingAdminId: ''
        });
        if (res.data === 'Already Registerd With This employee id') {
          // User found, navigate to the status page
          setPopup({ type: 'error', message: 'Already Registered Please Login' });
        } else {
          setPopup({ type: 'success', message: res.data });
          navigate(`/all-users/${id}`);
        }
      } catch (error) {
        console.error('Error Registering the Employee:', error);
        if (error.response && error.response.data) {
          setPopup({ type: 'error', message: error.response.data });
        } else {
          setPopup({ type: 'error', message: 'Registration not completed due to some technical issues' });
        }
      }
  };

    const closePopup = () => {
      setPopup(null);
    };


  return (
  
    <div className="content-section flex-wrap">
      <Navbar />
      <div className='body-section'>
        <div className='form-section'>
          <div className="form-control">
            <h2>Add Employee</h2>
            <form className="edit-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="first_name">First Name</label>
                  <input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} />
                </div>
          
                <div className="form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="employeeId">Employee ID</label>
                  <input type="number" id="employeeId" name="employeeId" value={formData.employeeId} onChange={handleChange} />
                </div>
                
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                </div>
              </div>

              <div className='form-row'>
                <div className="form-group">
                  <label htmlFor="seat_allocation">Seat Allocation</label>
                  <input type="text" id="seat_allocation" name="seat_allocation" value={formData.seat_allocation} onChange={handleChange} />
                </div>

                <div className="form-group">
                  <label htmlFor="joining_date">Joining Date</label>
                  <input type="date" id="joining_date" name="joining_date" value={formData.joining_date} onChange={handleChange} />
                </div>
              </div>
          
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="contact_no">Contact Number</label>
                  <input type="number" id="contact_no" name="contact_no" value={formData.contact_no} onChange={handleChange} />
                </div>

                <div className="form-group role-container">
                  <label htmlFor="role">Role</label>
                    <Select 
                        isClearable={true} 
                        id="role" 
                        closeMenuOnSelect={true}
                        name="role"
                        value={selectedOption}
                        defaultValue={selectedOption}
                        onChange={handleSelectRoleChange} 
                        options={roleOptions}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: '#e7daef',
                            primary: '#5c2680',
                          },
                        })}
                    />
                </div>

                <div className="form-group">
                <label htmlFor="contact_no">Access Type</label>
                  <select name="access_type" id="access_type" value={formData.access_type} onChange={handleChange}>
                      <option value="">Please select..</option>
                      <option value="1">Admin</option>
                      <option value="2">User</option>
                      <option value="3">Special User</option>
                  </select>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group'>
                  <label>Profile Picture:</label>
                  <div className='upload_image_section'>
                    <input type="file" name="profile_pic" id="profile_pic" onChange={convertToBase64} />
                    {image === ""|| image === null ? "": <img width={100} height={100} src={image}/>}
                  </div>
                </div>
              </div>
            
              <div className="form-row">
                <span className="bg-red">* All fields are requierd</span>
              </div>
              <button type="submit" className='button-style add-button'>Save</button>
            </form>
          </div>
        </div>
      </div>
      {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
    </div>
  
  )
}

export default AddUserPage