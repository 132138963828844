import React from 'react';
import '../../styles/CardItem.css';
//import { useNavigate } from 'react-router-dom';

const CardItem = (values) => {
    // const navigate = useNavigate();
    console.log('item',values);
    const actionNav = () => {
        window.open(values.navUrl, '_blank');
    };

    return (
        <div className='grid-item card'>
            <div className='card-item-image'>
                <img className="card-img-top" src={values.imgUrl} alt="Card image cap" />
            </div>
            <div className="card-body d-flex flex-column">
                <h5>{values.title}</h5>
                <span className="card-text">{values.description}</span>
                <button className='grid-item-btn' onClick={actionNav}>Click Here</button>
            </div>
        </div>
    );
};

export default CardItem;
