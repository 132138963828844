import React from 'react'
import '../../styles/navbar.css'

const Navbar = () => {
  return (
        <div className='banner-div'>
          <div className='banner-title'>
            <span>Polus Corporate Portal</span>
          </div>
          <div className='logo-div'>
            {/* <img src='/images/polus.webp' alt="Logo" className="logo" /> */}
          </div>
        </div>
  
  )
}

export default Navbar