import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import '../../styles/users.css'; // Import the CSS file
import Popup from '../../components/PopUps/Popup';
import { useNavigate, useParams} from 'react-router-dom';
import baseUrl from '../../Config/config';
// import Sidebar from '../../components/SideBars/SideBar';
import Navbar from '../../components/NavBar/Navbar';
import api from '../../api/api';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
// import SideBarPage from './SideBarPage';

const UpdateUserPage = () => {

  const { id, empId} = useParams();
  const [details, setDetails] = useState(null);
  const [image,setImage]= useState("");
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
      first_name: '',
      last_name: '',
      employeeId: '',
      email: '',
      contact_no: '',
      seat_allocation:'',
      role: '',
      roleId: '',
      joining_date:'',
      access_type:'',
      adminId: Number(id),
  });
  const [popup, setPopup] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [accessTypeId, setAccessTypeId] = useState(null);
  const accessType = [
    {
      value: 1,
      label:'Admin'
    },
    {
      value: 2,
      label:'User'
    },
    {
      value: 3,
      label:'Special User'
    }
  ]

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const token = localStorage.getItem('token'); //retrive the token from the local storage
        if(!token){
          navigate("/login")
        }else{
          const response = await api.get(`${baseUrl}/api/user-details/${empId}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          setDetails(response.data);
          setFormData(response.data)
          setImage(response.data.profile_pic);
          setAccessTypeId(response.data.access_type);

          const roleResponse = await api.get(`${baseUrl}/api/all-roles`, {
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
        });
        const options = roleResponse.data.map(role => ({
          value: role.roleId,
          label: role.roleName
        }));
        setRoleOptions(options);
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    fetchDetails();
  }, [id, empId]);

  useEffect(() => {
    getSelectedEmpData();
  }, [roleOptions]);

  const getSelectedEmpData = async () => {
    if(formData.role !== null){
      const selectedData = roleOptions.filter(selectData=> selectData.label === formData.role );
      setSelectedOption(selectedData);
    }
  }

  const convertToBase64 = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (file.size > 100 * 1024) { // 100KB = 100 * 1024 bytes
        setPopup({ type: 'error', message: 'File size must be below 100 KB' });
        e.target.value = ''; // Reset the file input field
        return;
      } else {
        setPopup({ type: 'info', message: 'File size: ' + file.size + ' bytes' });
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(reader.result);
        setImage(reader.result);
      };
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'access_type'){
      console.log('value',value);
      setAccessTypeId(value);
    }
    const newValue = (name === 'employeeId' || name === 'contact_no' || name === "access_type") ? Number(value) : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSelectRoleChange = (data,action) => {
    if (action.name === 'role' && data !== null) {
      setSelectedOption(data)
      setFormData({
        ...formData,
        [action.name]: data.label,
        roleId : data.value
      });
    } else {
      setSelectedOption(null)
      setFormData({
        ...formData,
        [action.name]: null,
        roleId : null
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      first_name: formData.first_name,
      last_name:formData.last_name,
      employeeId:formData.employeeId,
      email:formData.email,
      contact_no:formData.contact_no,
      seat_allocation:formData.seat_allocation,
      joining_date:formData.joining_date,
      profile_pic:image,
      roleId:formData.roleId,
      access_type:formData.access_type,
      adminId: formData.adminId
    }

    try {
      const token = localStorage.getItem('token'); //retrive the token from the local storage
      const res = await api.post(`${baseUrl}/api/update-user/${empId}`, requestData, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      navigate(`/all-users/${id}`);
    } catch (error) {
      console.error('Error registering the Employee:', error);
      if (error.response && error.response.data) {
        setPopup({ type: 'error', message: error.response.data });
      } else {
        setPopup({ type: 'error', message: 'Registration not completed due to some technical issues' });
      }
    }
  };

  function formatDateString(dateString) {
    // Convert the date string to a Date object
    const date = new Date(dateString);
  
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
  
    // Format the date to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  const closePopup = () => {
    setPopup(null);
  };

  const backFn = () => {
    navigate(-1)
  }

  return (
<div className='content-section flex-wrap'>
  <Navbar />
  <div className='body-section'>
        {
            details && 
            <div className='form-section'>
                  <div className='backBtn' onClick={backFn}><HiOutlineArrowSmLeft className='nxt-prev-icon' /></div>

              <div className='form-control'>
                <h2>Edit Personal Data</h2>
                <form className="edit-form" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="first_name">First Name</label>
                      <input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} />
                    </div>
              
                    <div className="form-group">
                      <label htmlFor="last_name">Last Name</label>
                      <input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="employeeId">Employee ID</label>
                      <input type="number" id="employeeId" name="employeeId" value={formData.employeeId} onChange={handleChange} />
                    </div>
              
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                  </div>

                  <div className='form-row'>
                    <div className="form-group">
                      <label htmlFor="seat_allocation">Seat Allocation</label>
                      <input type="text" id="seat_allocation" name="seat_allocation" value={formData.seat_allocation} onChange={handleChange} />
                    </div>

                    <div className="form-group">
                      <label htmlFor="joining_date">Joining Date</label>
                      <input type="date" id="joining_date" name="joining_date" value={formatDateString(formData.joining_date)} onChange={handleChange} />
                    </div>
                  </div>
              
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="contact_no">Contact Number</label>
                      <input type="number" id="contact_no" name="contact_no" value={formData.contact_no} onChange={handleChange} />
                    </div>

                    <div className="form-group role-container ">
                    <label htmlFor="role">Role</label>
                      <Select 
                        isClearable={true} 
                        id="role" 
                        closeMenuOnSelect={true}
                        name="role"
                        value={selectedOption}
                        defaultValue={selectedOption}
                        onChange={handleSelectRoleChange} 
                        options={roleOptions}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: '#e7daef',
                            primary: '#5c2680',
                          },
                        })}
                      />
                    </div>
              
                    <div className="form-group">
                    <label htmlFor="access_type">Access Type</label>
                      <select name="access_type" onChange={handleChange} value={accessTypeId}>
                        <option value="">Please select..</option>
                        {accessType.map((option) => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            );
                        })}
                        </select>
                    </div>
                  </div>

                  <div className='form-row'>
                    <div className='form-group'>
                      <label>Profile Picture:</label>
                      <div className='upload_image_section'>
                        <input type="file" name="profile_pic" id="profile_pic" onChange={convertToBase64} />
                        {image === ""|| image === null ? "": <img width={100} height={100} src={image}/>}
                      </div>
                    </div>
                  </div>

                  <button type="submit" className='button-style'>Update</button>
                </form>
              </div>
            </div>
        }
 

  {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}

  </div>
</div>
    
  );
};

export default UpdateUserPage;
